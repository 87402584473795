
import { Component, Vue } from 'vue-property-decorator';
import client from '@/shared/plugins/content';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  layout: 'default',
  metaInfo: {
    title: 'Visitor Privacy Policy ',
    // override the parent template and just use the above title only
  },
})
export default class VisitorPrivacyPolicy extends Vue {
  title = '';

  terms = '';

  get background() {
    // eslint-disable-next-line global-require
    return require('@/assets/images/pages/wave-gray.webp');
  }

  mounted() {
    client
      .getEntry('5CDkusWuIqAGpqwb7YvX4Y')
      .then((entry: any) => {
        const richText = entry.fields.content;

        return documentToHtmlString(richText);
      })
      .then((renderedHtml: any) => {
        const html = document.getElementById('rich-text-body');
        if (html) html.innerHTML = renderedHtml;
      });
  }
}
